import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form, FieldArray } from 'formik';
import * as yup from 'yup';
import InfoIcon from '@mui/icons-material/Info';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  SvgIcon,
  Paper,
  Tooltip,
  InputAdornment,
} from '@mui/material';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { AddCircleOutline } from '@mui/icons-material';
import Switch from '@mui/material/Switch';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';
import UploadFileOnChange from '../../../components/UploadFileOnChange.jsx/UploadFileOnChange';
import PreviewFile from '../../../components/previewFile/PreviewFile';
import { updateFormData } from '../../../Store/features/Plans/plansSlice';

import { ReactComponent as EditIcon } from '../../../theme/images/editTableIcon.svg';
import { ReactComponent as DeleteIcon } from '../../../theme/images/deleteTableIcon.svg';

import {
  getRegionList,
  getCitiesListWithoutSearch,
  getFacilitiesList,
  getServicesList,
  getpostSaleServicesList,
  getListDocumentsByTargetData,
  getCitiesListWithRegion,
  getDistrictListWithCity,
} from '../../../network/apis';
import ClientHeadTable from '../../../components/clientHeadTable/ClientHeadTable';
import DeleteFileModal from '../../../components/deleteFileModal/DeleteFileModal';

const PlanDataValidationSchema = yup.object().shape({
  nameEn: yup
    .string()
    .required('This field is required please enter english text')
    .test('is-english', 'Please enter English text', (value) => {
      const englishRegex = /^[A-Za-z0-9_-\s]*$/;
      return englishRegex.test(value);
    }),
  nameAr: yup
    .string()
    .required('This field is required please enter Arabic text')
    .test('is-arabic-or-number-or-dash', 'Please enter Arabic text\nيرجي ادخال نص عربي', (value) => {
      const arabicRegex = /^[\u0600-\u06FF\u0660-\u0669\s0-9-]+$/;
      const englishRegex = /[A-Za-z]/;
      return arabicRegex.test(value) && !englishRegex.test(value);
    }),
  location: yup
    .string()
    .required('This field is required Please enter Geographical Location')
    .test('contains-lat-lng', 'Please enter valid data format', (value) => {
      const latLngRegex = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
      return latLngRegex.test(value);
    }),
  totalLands: yup
    .number()
    .typeError('Please enter valid data format')
    .required('This field is required Please enter Integer Number')
    .positive('Please enter Integer Number')
    .integer('Please enter valid data format'),
  totalArea: yup
    .string()
    .required('This field is required Please enter a number')
    .test('is-valid-number', 'Please enter a valid number', (value) => {
      const formattedValue = parseFloat(value?.replace(',', '.'));
      return !Number.isNaN(formattedValue) && formattedValue >= 1;
    }),
  landOffered: yup
    .number()
    .typeError('Please enter valid data format')
    .required('This field is required Please enter Integer Number')
    .positive('The number must be positive')
    .integer('Please enter valid data format'),
  regionId: yup.string().required('This field is required Please choose value'),
  cityId: yup.string().required('This field is required Please choose value'),
  services: yup
    .array()
    .of(yup.string())
    .min(1, 'This field is required Please choose value')
    .required('This field is required Please choose value'),
  afterSaleServicesIds: yup
    .array()
    .of(yup.string())
    .min(1, 'This field is required Please choose value')
    .required('This field is required Please choose value'),
  reservationStatus: yup.string().required('This field is required Please choose value'),
  facilities: yup
    .array()
    .of(yup.string())
    .min(1, 'This field is required Please choose value')
    .required('This field is required Please choose value'),
  planBanner: yup.mixed().when(['displayConfig', 'recentProject'], {
    is: (displayConfig, recentPlan) => displayConfig === 'BANNER' && recentPlan === 'ACTIVE',
    then: yup.mixed().required('This field is required'),
    otherwise: yup.mixed(),
  }),
  endDate: yup.date().when('recentPlan', {
    is: 'ACTIVE',
    then: yup.date().required('This field is required').nullable(),
    otherwise: yup.date().nullable(),
  }),
});

const CustomSwitch = ({ field, form, viewMode, t, isRtl }) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  const handleChange = (event) => {
    setFieldValue(name, event.target.checked ? 'ACTIVE' : 'IN_ACTIVE');
  };

  return (
    <FormControlLabel
      sx={{ direction: isRtl ? 'rtl' : 'ltr' }}
      disabled={viewMode}
      control={<Switch checked={value === 'ACTIVE'} onChange={handleChange} />}
      label={t('General.Status')}
    />
  );
};

const GeneralData = ({
  editData = {},
  isEditMode,
  isViewMode,
  handleNext,
  handleDelete,
  uploadedFilesUploaded,
  addItem,
  deleteItem,
  refetch,
  setRefetch,
  selectedFacilities,
  setSelectedFacilities,
  selectedServices,
  setSelectedServices,
  selectedAfterSaleServices,
  setSelectedPostSaleServices,
}) => {
  const stateKey = new Date();
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const [deleteFileData, setDeleteFileData] = useState({
    open: false,
    type: '',
    id: '',
    name: '',
  });

  const [changeVirtualTour, setChangeVirtualTour] = useState(editData?.virtualType !== 'LINK');
  const [loading, setLoading] = useState(false);
  const formData = useSelector((state) => state.plan.formData);

  // region states
  const [regionOptions, setRegionOptions] = useState([]);
  const [dropdownOpened, setDropdownOpened] = useState(false);

  const getAllRegions = (page, search) => {
    setLoading(true);
    getRegionList(0, 100, search)
      .then((res) => {
        setRegionOptions(res.data);

        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    if (!dropdownOpened) {
      getAllRegions(0, '');
      setDropdownOpened(true);
    }
  }, [editData, dropdownOpened]);
  // city select
  const [citiesList, setCitiesList] = useState([]);
  const [citydropdownOpened, setCityDropdownOpened] = useState(false);

  const getCities = () => {
    setLoading(true);
    getCitiesListWithoutSearch()
      .then((res) => {
        setCitiesList(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };
  const getCitiesWithRegion = (page, size, region) => {
    setLoading(true);
    getCitiesListWithRegion(page, size, region)
      .then((res) => {
        setCitiesList(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };
  useEffect(() => {
    if (editData?.regionId) getCitiesWithRegion(0, 200, editData.regionId);
  }, [editData, citydropdownOpened]);

  // District states

  const [districtdropdownOpened, setDistrictDropdownOpened] = useState(false);
  const [districtList, setDistrictList] = useState([]);

  const getDistrictWithCity = (page, size, city, district) => {
    setLoading(true);
    getDistrictListWithCity(page, size, city, district)
      .then((res) => {
        setDistrictList(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };
  useEffect(() => {
    if (editData?.cityId) getDistrictWithCity(0, 200, editData.cityId);
  }, [editData, districtdropdownOpened]);

  // facilities select
  const [facilitiesOptions, setFacilities] = useState([]);
  const [facilityDropdownOpened, setFacilityDropdownOpened] = useState(false);
  const CustomArrowIcon = ({ isRtl }) => (
    <SvgIcon style={{ transform: 'rotate(-360deg)', color: '#637381' }}>
      <path d="M7 10l5 5 5-5z" />
    </SvgIcon>
  );

  const getAllFacilities = (page, search) => {
    setLoading(true);
    getFacilitiesList(page, search)
      .then((res) => {
        setFacilities(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    if (!facilityDropdownOpened) {
      getAllFacilities(0, '');
      setFacilityDropdownOpened(true);
    }
  }, [editData, facilityDropdownOpened]);

  // services select

  const [servicesOptions, setServices] = useState([]);
  const [serviceDropdownOpened, setServiceDropdownOpened] = useState(false);

  const getAllServicesRequest = (page, search) => {
    setLoading(true);
    getServicesList(page, search)
      .then((res) => {
        setServices(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };
  useEffect(() => {
    if (!serviceDropdownOpened) {
      getAllServicesRequest(0, '');
      setServiceDropdownOpened(true);
    }
  }, [editData, serviceDropdownOpened]);

  // postSaleServices select
  const [postSaleServicesOptions, setPostSaleServicesOptions] = useState([]);
  const [postSaleServicesDropDownOpened, setpostSaleServicesDropDownOpened] = useState(false);

  const getpostSaleServicesListRequest = (page, search) => {
    setLoading(true);
    getpostSaleServicesList(page, search)
      .then((res) => {
        setPostSaleServicesOptions(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };
  useEffect(() => {
    if (!postSaleServicesDropDownOpened) {
      getpostSaleServicesListRequest(0, '');
      setpostSaleServicesDropDownOpened(true);
    }
  }, [editData, setpostSaleServicesDropDownOpened]);

  // document type select
  const [documentTypeList, setDocumentTypeList] = useState([]);

  const getProjectDocumentType = (project) => {
    setLoading(true);
    getListDocumentsByTargetData(project)
      .then((res) => {
        setDocumentTypeList(res.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    getProjectDocumentType('PROJECT');
    setFacilityDropdownOpened(true);
  }, [editData, facilityDropdownOpened]);

  const handleClick = () => {
    if (editData.planBanner instanceof File) {
      const fileURL = URL.createObjectURL(editData.planBanner);
      window.open(fileURL, '_blank');
    } else if (typeof editData.planBanner === 'string') {
      window.open(editData.planBanner, '_blank');
    }
  };
  const TABLE_HEAD = [
    { id: 'landmark-name-en', label: t('General.nameEn'), alignRight: false },
    { id: 'landmark-name-ar', label: t('General.nameAr'), alignRight: false },
    { id: 'landmark-distance', label: t('General.distance'), alignRight: false },
    { id: 'landmark-distance-unit', label: t('General.distanceUnit'), alignRight: false },
  ];

  if (!isViewMode) {
    TABLE_HEAD.push({ id: 'action', label: t('General.Action'), alignRight: false });
  }
  const distanceUnit = [
    {
      id: 1,
      value: 'M',
      label: t('General.meters'),
    },
    {
      id: 2,
      value: 'K',
      label: t('General.kilometers'),
    },
  ];
  const focusOnSelect = useRef(null);
  const validationSchema = yup.object().shape({
    nameEn: yup.string().required(t('General.required_field')),
    nameAr: yup.string().required(t('General.required_field')),
    detailsEn: yup.string().required(t('General.required_field')),
    detailsAr: yup.string().required(t('General.required_field')),
    addressEn: yup.string().required(t('General.required_field')),
    addressAr: yup.string().required(t('General.required_field')),
    location: yup.string().required(t('General.required_field')),
    totalArea: yup.number().required(t('General.required_field')),
    licenceId: yup.string().required(t('General.required_field')),
    totalLands: yup.number().required(t('General.required_field')),
    landOffered: yup.number().required(t('General.required_field')),
  });

  if ((isEditMode || isViewMode) && editData.length === 0) {
    return null;
  }
  const uniqueKey = Date.now();
  return (
    <Formik
      initialValues={
        isEditMode || isViewMode
          ? {
              ...editData,
            }
          : {
              ...formData,
              states: 'ACTIVE',
            }
      }
      onSubmit={(values, { setSubmitting }) => {
        dispatch(
          updateFormData({
            ...values,
            virtualType: changeVirtualTour,
            facilities: selectedFacilities,
            services: selectedServices,
            afterSaleServices: selectedAfterSaleServices,
          })
        );
        dispatch(handleNext());
        setSubmitting(false);
      }}
      validationSchema={validationSchema}
    >
      {({ values, errors, touched, handleChange, handleBlur, setFieldTouched, handleSubmit, setFieldValue }) => (
        <Form onSubmit={handleSubmit} style={{ height: '100%' }}>
          <Grid
            style={{ marginTop: '10px' }}
            rowSpacing={2}
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid
              rowSpacing={2}
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
              style={{
                padding: '0.5rem 0.87rem 1rem',
                border: '1px solid #efefef',
                margin: '0.5rem',
                borderRadius: '14px',
                boxShadow: '3px 2px 5px #d6d5d5',
              }}
            >
              <Grid item xs={2} sm={4} md={3}>
                <Typography variant="body1" gutterBottom>
                  {t('ManageProject.advertiseId')}
                </Typography>
                <TextField
                  name="advertiseId"
                  placeholder={t('ManageProject.enterAdvertiseId')}
                  required
                  fullWidth
                  value={values.advertiseId}
                  variant="outlined"
                  onChange={handleChange}
                  disabled
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Tooltip title={<Typography variant="body2">{t('General.auto_gentrate_text')} </Typography>}>
                          <InfoIcon />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={4}>
                <Typography variant="body1" gutterBottom>
                  {t('PLANS.planNameEn')} <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  name="nameEn"
                  placeholder={t('PLANS.planNameEn')}
                  required
                  fullWidth
                  disabled={isViewMode}
                  value={values.nameEn}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.nameEn && Boolean(errors.nameEn)}
                  helperText={touched.nameEn && errors.nameEn}
                />
              </Grid>

              <Grid item xs={2} sm={4} md={5}>
                <Typography variant="body1" gutterBottom>
                  {t('PLANS.planNameAr')}
                </Typography>
                <TextField
                  name="nameAr"
                  placeholder={t('PLANS.planNameAr')}
                  required
                  fullWidth
                  value={values.nameAr}
                  disabled={isViewMode}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.nameAr && Boolean(errors.nameAr)}
                  helperText={
                    touched.nameAr &&
                    errors.nameAr && (
                      <span dangerouslySetInnerHTML={{ __html: errors.nameAr.replace('\n', '<br />') }} />
                    )
                  }
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('General.detailsEn')} <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  name="detailsEn"
                  placeholder={t('General.enterDetails')}
                  rows={4}
                  multiline
                  required
                  disabled={isViewMode}
                  fullWidth
                  value={values.detailsEn}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.detailsEn && Boolean(errors.detailsEn)}
                  helperText={touched.detailsEn && errors.detailsEn}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('General.detailsAr')} <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  name="detailsAr"
                  placeholder={t('General.enterDetails')}
                  rows={4}
                  multiline
                  required
                  fullWidth
                  disabled={isViewMode}
                  value={values.detailsAr}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.detailsAr && Boolean(errors.detailsAr)}
                  helperText={touched.detailsAr && errors.detailsAr}
                />
              </Grid>
              <Grid item xs={2} sm={6} md={4}>
                <Stack>
                  <Typography variant="body1" gutterBottom>
                    {t('General.region')} <span style={{ color: 'red' }}>*</span>{' '}
                  </Typography>
                  <Select
                    labelId="regionId"
                    id="regionId"
                    onOpen={() => {
                      if (!dropdownOpened) {
                        getAllRegions(0, '');
                        setDropdownOpened(true);
                      }
                    }}
                    disabled={isViewMode}
                    value={values.regionId}
                    onChange={(event) => {
                      setFieldValue('regionId', event.target.value);
                    }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (!selected) {
                        return <span style={{ color: '#9e9e9e' }}> {t('General.select')}</span>;
                      }
                      return isRtl
                        ? regionOptions?.content?.find((option) => option.id === selected)?.nameAr
                        : regionOptions?.content?.find((option) => option.id === selected)?.nameEn;
                    }}
                  >
                    {loading ? (
                      <MenuItem>
                        <CircularProgress size={24} />
                      </MenuItem>
                    ) : (
                      regionOptions?.content?.map((option, index) => (
                        <MenuItem key={index} value={option.id}>
                          {isRtl ? option.nameAr : option.nameEn}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </Stack>
              </Grid>
              <Grid item xs={2} sm={6} md={4}>
                <Stack>
                  <InputLabel id="cityId">
                    {t('General.City')} <span style={{ color: 'red' }}>*</span>{' '}
                  </InputLabel>
                  <Select
                    sx={{ mt: 1 }}
                    labelId="cityId"
                    id="cityId"
                    value={values.cityId}
                    disabled={isViewMode}
                    onOpen={() => {
                      getCitiesWithRegion(0, 200, values.regionId);
                    }}
                    onChange={(event) => {
                      setFieldValue('cityId', event.target.value);
                    }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (!selected) {
                        return <span style={{ color: '#9e9e9e' }}> {t('General.select')}</span>;
                      }
                      return isRtl
                        ? citiesList?.content?.find((option) => option.id === selected)?.nameAr
                        : citiesList?.content?.find((option) => option.id === selected)?.nameEn;
                    }}
                  >
                    {loading ? (
                      <MenuItem>
                        <CircularProgress size={24} />
                      </MenuItem>
                    ) : (
                      citiesList?.content?.map((option, index) => (
                        <MenuItem key={index} value={option.id}>
                          {isRtl ? option.nameAr : option.nameEn}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                  {touched.cityId && errors.cityId && <FormHelperText error>{errors.cityId}</FormHelperText>}
                </Stack>
              </Grid>
              <Grid item xs={2} sm={6} md={4}>
                <Stack>
                  <InputLabel id="districtId">
                    {t('General.district')} <span style={{ color: 'red' }}>*</span>{' '}
                  </InputLabel>
                  <Select
                    sx={{ mt: 1 }}
                    labelId="districtId"
                    id="districtId"
                    value={values.districtId}
                    onOpen={() => {
                      getDistrictWithCity(0, 200, values.cityId, values.districtId);
                    }}
                    onChange={(event) => {
                      setFieldValue('districtId', event.target.value);
                    }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (!selected) {
                        return <span style={{ color: '#9e9e9e' }}> {t('General.select')}</span>;
                      }
                      return isRtl
                        ? districtList?.content?.find((option) => option.id === selected)?.nameAr
                        : districtList?.content?.find((option) => option.id === selected)?.nameEn;
                    }}
                  >
                    {loading ? (
                      <MenuItem>
                        <CircularProgress size={24} />
                      </MenuItem>
                    ) : (
                      districtList?.content?.map((option, index) => (
                        <MenuItem key={index} value={option.id}>
                          {isRtl ? option.nameAr : option.nameEn}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                  {touched.districtId && errors.districtId && (
                    <FormHelperText error>{errors.districtId}</FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('General.addressEn')} <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  name="addressEn"
                  placeholder={t('General.addressEn')}
                  required
                  fullWidth
                  disabled={isViewMode}
                  value={values.addressEn}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.addressEn && Boolean(errors.addressEn)}
                  helperText={touched.addressEn && errors.addressEn}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('General.addressAr')}
                  <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  name="addressAr"
                  placeholder={t('General.addressAr')}
                  required
                  disabled={isViewMode}
                  fullWidth
                  value={values.addressAr}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.addressAr && Boolean(errors.addressAr)}
                  helperText={touched.addressAr && errors.addressAr}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('General.GeoLocation')} <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  name="location"
                  placeholder={t('General.GeoLocation')}
                  required
                  disabled={isViewMode}
                  fullWidth
                  value={values.location}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.location && Boolean(errors.location)}
                  helperText={touched.location && errors.location}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('General.totalArea')} <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  name="totalArea"
                  placeholder={t('General.totalArea')}
                  required
                  fullWidth
                  disabled={isViewMode}
                  value={values.totalArea}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.totalArea && Boolean(errors.totalArea)}
                  helperText={touched.totalArea && errors.totalArea}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('General.licenseId')}
                </Typography>
                <TextField
                  name="licenceId"
                  placeholder={t('General.licenseId')}
                  required
                  disabled={isViewMode}
                  fullWidth
                  value={values.licenceId}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.licenceId && Boolean(errors.licenceId)}
                  helperText={touched.licenceId && errors.licenceId}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Stack sx={{ direction: isRtl ? 'rtl ' : 'ltr' }}>
                  <InputLabel id="facilities"> {t('General.facilities')}</InputLabel>

                  <Select
                    sx={{ mt: 1 }}
                    name="facilities"
                    labelId="facilities"
                    multiple
                    disabled={isViewMode}
                    id="facilities"
                    value={selectedFacilities}
                    displayEmpty
                    IconComponent={() => <CustomArrowIcon isRtl={isRtl} />}
                    input={<OutlinedInput label="Multiple Select" />}
                    onOpen={() => {
                      if (!facilityDropdownOpened) {
                        getAllFacilities(0, '');
                        setFacilityDropdownOpened(true);
                      }
                    }}
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      setSelectedFacilities(value);
                    }}
                    renderValue={(selected) => {
                      if (selected?.length === 0) {
                        return <span style={{ color: '#9e9e9e' }}>{t('General.select')}</span>;
                      }
                      return selected
                        ?.map((value) =>
                          isRtl
                            ? facilitiesOptions?.content?.find((option) => option.id === value)?.nameAr
                            : facilitiesOptions?.content?.find((option) => option.id === value)?.nameEn || ''
                        )
                        ?.join(', ');
                    }}
                  >
                    {loading ? (
                      <MenuItem>
                        <CircularProgress size={24} />
                      </MenuItem>
                    ) : (
                      facilitiesOptions?.content?.map((option, index) => (
                        <MenuItem key={index} value={option.id}>
                          {isRtl ? option.nameAr : option.nameEn}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </Stack>
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Stack>
                  <InputLabel id="services">{t('General.services')}</InputLabel>
                  <Select
                    sx={{ mt: 1 }}
                    labelId={t('General.services')}
                    name="services"
                    multiple
                    id="services"
                    value={selectedServices}
                    disabled={isViewMode}
                    displayEmpty
                    IconComponent={() => <CustomArrowIcon isRtl={isRtl} />}
                    input={<OutlinedInput label="Multiple Select" />}
                    onOpen={() => {
                      if (!serviceDropdownOpened) {
                        getAllServicesRequest(0, '');
                        setServiceDropdownOpened(true);
                      }
                    }}
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      setSelectedServices(value);
                    }}
                    renderValue={(selected) => {
                      if (selected?.length === 0) {
                        return <span style={{ color: '#9e9e9e' }}> {t('General.select')}</span>;
                      }

                      return selected
                        ?.map((value) =>
                          isRtl
                            ? servicesOptions?.content?.find((option) => option.id === value)?.nameAr || ''
                            : servicesOptions?.content?.find((option) => option.id === value)?.nameEn || ''
                        )
                        ?.join(', ');
                    }}
                  >
                    {loading ? (
                      <MenuItem>
                        <CircularProgress size={24} />
                      </MenuItem>
                    ) : (
                      servicesOptions?.content?.map((option, index) => (
                        <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} key={index} value={option.id}>
                          {isRtl ? option.nameAr : option.nameEn}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </Stack>
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Stack>
                  <InputLabel id="services">{t('General.postService')}</InputLabel>
                  <Select
                    sx={{ mt: 1 }}
                    disabled={isViewMode}
                    name="afterSaleServices"
                    labelId="afterSaleServicesIds"
                    multiple
                    IconComponent={() => <CustomArrowIcon isRtl={isRtl} />}
                    id="afterSaleServicesIds"
                    value={selectedAfterSaleServices}
                    onOpen={() => {
                      if (!serviceDropdownOpened) {
                        getpostSaleServicesListRequest(0, '');
                        setpostSaleServicesDropDownOpened(true);
                      }
                    }}
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      setSelectedPostSaleServices(value);
                    }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (selected?.length === 0) {
                        return <span style={{ color: '#9e9e9e' }}> {t('General.select')}</span>;
                      }

                      return selected
                        ?.map((value) =>
                          isRtl
                            ? postSaleServicesOptions?.content?.find((option) => option.id === value)?.nameAr || ''
                            : postSaleServicesOptions?.content?.find((option) => option.id === value)?.nameEn || ''
                        )
                        ?.join(', ');
                    }}
                    input={<OutlinedInput label="Multiple Select" />}
                  >
                    {loading ? (
                      <MenuItem>
                        <CircularProgress size={24} />
                      </MenuItem>
                    ) : (
                      postSaleServicesOptions?.content?.map((option, index) => (
                        <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} key={index} value={option.id}>
                          {isRtl ? option.nameAr : option.nameEn}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </Stack>
              </Grid>
            </Grid>
            <Grid
              rowSpacing={2}
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
              style={{
                padding: '0.5rem 0.87rem 1rem',
                border: '1px solid #efefef',
                margin: '0.5rem',
                borderRadius: '14px',
                boxShadow: '3px 2px 5px #d6d5d5',
                direction: isRtl ? 'rtl' : 'ltr',
              }}
            >
              <Grid item xs={2} sm={4} md={6}>
                <Stack>
                  <InputLabel id="reservationStatus">{t('PLANS.landType')}</InputLabel>
                  <Select
                    sx={{ mt: 1 }}
                    placeholder={t('General.landType')}
                    labelId="landType"
                    id="landType"
                    value={values.landType}
                    disabled={isViewMode}
                    IconComponent={() => <CustomArrowIcon isRtl={isRtl} />}
                    onChange={(event) => {
                      handleChange({
                        target: {
                          name: 'landType',
                          value: event.target.value,
                        },
                      });
                    }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (!selected) {
                        return <span style={{ color: '#9e9e9e' }}>{t('General.select')}</span>;
                      }
                      switch (selected) {
                        case 'ALL':
                          return t('General.all');
                        case 'RESIDENTIAL':
                          return t('Units.residential');
                        case 'COMMERCIAL':
                          return t('Units.commercial');
                        default:
                          return t('General.select');
                      }
                    }}
                  >
                    <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} value="ALL">
                      {t('General.all')}
                    </MenuItem>
                    <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} value="RESIDENTIAL">
                      {t('Units.residential')}
                    </MenuItem>
                    <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} value="COMMERCIAL">
                      {t('Units.commercial')}
                    </MenuItem>
                  </Select>
                </Stack>
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('PLANS.landNumber')}
                  <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  name="totalLands"
                  placeholder={t('PLANS.landNumber')}
                  required
                  fullWidth
                  value={values.totalLands}
                  disabled={isViewMode}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.totalLands && Boolean(errors.totalLands)}
                  helperText={touched.totalLands && errors.totalLands}
                />
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('PLANS.landOfferedNumber')}
                  <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  name="landOffered"
                  placeholder={t('PLANS.landOfferedNumber')}
                  required
                  fullWidth
                  disabled={isViewMode}
                  value={values.landOffered}
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.landOffered && Boolean(errors.landOffered)}
                  helperText={touched.landOffered && errors.landOffered}
                />
              </Grid>
            </Grid>

            <Grid
              rowSpacing={2}
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
              style={{
                padding: '0.5rem 0.87rem 1rem',
                border: '1px solid #efefef',
                direction: isRtl ? 'rtl' : 'ltr',
                margin: '0.5rem',
                borderRadius: '14px',
                boxShadow: '3px 2px 5px #d6d5d5',
              }}
            >
              <Grid item xs={2} sm={4} md={6}>
                <Stack>
                  <InputLabel id="reservationStatus">{t('PLANS.recentPlan')}</InputLabel>
                  <Select
                    sx={{ mt: 1, direction: isRtl ? 'rtl' : 'ltr' }}
                    placeholder={t('PLANS.recentPlan')}
                    labelId="recentPlan"
                    id="recentPlan"
                    disabled={isViewMode}
                    value={values.recentPlan}
                    IconComponent={() => <CustomArrowIcon isRtl={isRtl} />}
                    onChange={(event) => {
                      handleChange({
                        target: {
                          name: 'recentPlan',
                          value: event.target.value,
                        },
                      });
                    }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (!selected) {
                        return <span style={{ color: '#9e9e9e' }}>{t('General.select')}</span>;
                      }
                      return selected === 'ACTIVE' ? t('General.Active') : t('General.InActive');
                    }}
                  >
                    <MenuItem value="ACTIVE">{t('General.Active')}</MenuItem>
                    <MenuItem value="IN_ACTIVE">{t('General.InActive')}</MenuItem>
                  </Select>
                </Stack>
              </Grid>

              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('General.endPublishing')}
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    sx={{ width: '100%' }}
                    disabled={isViewMode}
                    // label={<span style={{ textAlign: isRtl ? 'right' : 'left' }}>{t('General.endPublishing')}</span>}
                    value={values.endDate ? dayjs(values.endDate) : null}
                    onChange={(newValue) => {
                      const formattedDate = newValue.format('YYYY-MM-DD');
                      setFieldValue('endDate', formattedDate);
                    }}
                    renderInput={(params) => (
                      <TextField
                        label={t('General.endPublishing')}
                        InputLabelProps={{
                          style: { textAlign: isRtl ? 'right' : 'left' },
                        }}
                        {...params}
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
                {values.recentProject === 'ACTIVE' && !values.endDate && (
                  <Typography variant="body2" color="error">
                    {t('General.thisFieldIsRequired')}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">{t('General.displayConfig')}</FormLabel>
                  <Field name="displayConfig">
                    {({ field }) => (
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name={field.name}
                        value={field.value}
                        onChange={(e) => {
                          setFieldValue(field.name, e.target.value);
                        }}
                      >
                        <FormControlLabel
                          disabled={isViewMode}
                          value="BANNER"
                          control={<Radio />}
                          label={t('General.banner')}
                        />
                        <FormControlLabel
                          disabled={isViewMode}
                          value="RECENT"
                          control={<Radio />}
                          label={t('General.recent')}
                        />
                      </RadioGroup>
                    )}
                  </Field>
                </FormControl>
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('PLANS.planBanner')}
                </Typography>
                <UploadFileOnChange
                  setFieldValue={setFieldValue}
                  uniqueKey={uniqueKey + 1}
                  uploadedFilesUploaded={uploadedFilesUploaded}
                  viewMode={isViewMode}
                  type="PHOTO"
                  category="PLAN"
                  multiple={false}
                  label={t('PLANS.planBanner')}
                  isViewMode={isViewMode}
                  fieldName="planBanner"
                  maxSize={5000000} // 5MB
                  key={stateKey + 1}
                  acceptedTypes="image/*"
                  addItem={addItem}
                  deleteItem={deleteItem}
                />
                <Box>
                  {editData.planBanner && uploadedFilesUploaded?.planBanner?.length === 0 ? (
                    <PreviewFile
                      file={{
                        path: editData.planBanner,
                        displayName: editData?.planBanner,
                        id: uploadedFilesUploaded?.planBanner?.length,
                      }}
                    />
                  ) : (
                    ''
                  )}
                </Box>
                {/* {(values.recentProject === 'ACTIVE' || values.displayConfig === 'BANNER') &&
                  !values.projectBanner &&
                  isBannerUploaded && (
                    <Typography variant="body2" color="error">
                      This field is required
                    </Typography>
                  )} */}
              </Grid>
            </Grid>
            <Grid
              rowSpacing={2}
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
              style={{
                padding: '1rem 0.87rem 1rem 2.3rem',
                direction: isRtl ? 'rtl' : 'ltr',
                border: '1px solid #efefef',
                margin: '0.5rem',
                borderRadius: '14px',
                boxShadow: '3px 2px 5px #d6d5d5',
              }}
            >
              <Stack>
                <h3
                  style={{
                    marginTop: '0',
                    marginBottom: '0.5rem',
                    marginLeft: '0',
                    marginRight: '0',
                    padding: '',
                  }}
                >
                  {t('General.landmarks')}
                </h3>
              </Stack>
              <FieldArray name="nearestLandmarks">
                {({ push, remove }) => {
                  return (
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      {!isViewMode && (
                        <Grid
                          container
                          sx={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '1rem' }}
                          spacing={1}
                        >
                          <Grid item xs={12} sm={6} md={3}>
                            <Stack>
                              <InputLabel id="landmarkNameEn">{t('General.nameEn')}</InputLabel>
                              <TextField
                                id="landmarkNameEn"
                                name="landmarkNameEn"
                                value={values.landmarkNameEn}
                                placeholder={t('General.enterNameEn')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                fullWidth
                                variant="outlined"
                              />
                            </Stack>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <Stack>
                              <InputLabel id="landmarkNameAr">{t('General.nameAr')}</InputLabel>
                              <TextField
                                id="landmarkNameAr"
                                name="landmarkNameAr"
                                value={values.landmarkNameAr}
                                placeholder={t('General.enterNameAr')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                fullWidth
                                variant="outlined"
                              />
                            </Stack>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <Stack>
                              <InputLabel id="distance">{t('General.distance')}</InputLabel>
                              <TextField
                                id="distance"
                                name="distance"
                                type="number"
                                value={values.distance}
                                placeholder={t('General.enterDistance')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                fullWidth
                                variant="outlined"
                              />
                            </Stack>
                          </Grid>
                          <Grid item xs={12} sm={6} md={2}>
                            <Stack>
                              <InputLabel id="measure">{t('General.distanceUnit')} *</InputLabel>
                              <Select
                                labelId="measure"
                                ref={focusOnSelect}
                                id="measure"
                                name="measure"
                                value={values.measure}
                                onChange={(event) => setFieldValue('measure', event.target.value?.toString())}
                                displayEmpty // This prop ensures the placeholder is shown when no value is selected
                                renderValue={(selected) => {
                                  if (!selected) {
                                    return <em>{t('General.select')}</em>; // Placeholder text
                                  }
                                  const item = distanceUnit.find((option) => option.value === selected);
                                  return item?.label || <em>{t('General.select')}</em>;
                                }}
                              >
                                <MenuItem disabled value="">
                                  <em>{t('General.select')}</em>
                                </MenuItem>
                                {distanceUnit.map((option) => (
                                  <MenuItem key={option.id} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} sm={6} md={1}>
                            {' '}
                            <IconButton
                              onClick={() => {
                                if (
                                  values.landmarkNameEn &&
                                  values.landmarkNameAr &&
                                  values.distance &&
                                  values.measure
                                ) {
                                  push({
                                    landmarkNameEn: values.landmarkNameEn,
                                    landmarkNameAr: values.landmarkNameAr,
                                    distance: values.distance,
                                    measure: values.measure,
                                  });
                                  setFieldValue('landmarkNameEn', '');
                                  setFieldValue('landmarkNameAr', '');
                                  setFieldValue('distance', '');
                                  setFieldValue('measure', '');
                                }
                              }}
                              sx={{
                                marginTop: '15px !important',
                                width: '40px',
                                height: '40px',
                                borderRadius: '50%',
                                backgroundColor: '#000',
                                color: 'white',
                                '&:hover': {
                                  backgroundColor: '#303f9f',
                                },
                              }}
                            >
                              <AddCircleOutline />
                            </IconButton>
                          </Grid>
                        </Grid>
                      )}

                      <div
                        style={{
                          width: '100%',
                          maxWidth: '100%',
                          marginRight: 'auto',
                          marginLeft: 'auto',
                          maxHeight: '300px ',
                          overflowY: 'auto',
                        }}
                      >
                        <TableContainer component={Paper} sx={{ minWidth: 800 }}>
                          <Table>
                            <ClientHeadTable sortable={false} headLabel={TABLE_HEAD} />
                            <TableBody>
                              {values.nearestLandmarks?.map((row, index) => {
                                return (
                                  <TableRow key={index} tabIndex={-1}>
                                    <TableCell component="th" scope="row">
                                      <Typography
                                        sx={{
                                          color: 'text.secondary',
                                          fontWeight: '400',
                                          fontSize: '12px',
                                          lineHeight: '20px',
                                        }}
                                        variant="subtitle2"
                                        noWrap
                                      >
                                        {row?.landmarkNameEn}
                                      </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <Typography
                                        sx={{
                                          color: 'text.secondary',
                                          fontWeight: '400',
                                          fontSize: '12px',
                                          lineHeight: '20px',
                                        }}
                                        variant="subtitle2"
                                        noWrap
                                      >
                                        {row?.landmarkNameAr}
                                      </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <Typography
                                        sx={{
                                          color: 'text.secondary',
                                          fontWeight: '400',
                                          fontSize: '12px',
                                          lineHeight: '20px',
                                        }}
                                        variant="subtitle2"
                                        noWrap
                                      >
                                        {row?.distance}
                                      </Typography>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      <Typography
                                        sx={{
                                          color: 'text.secondary',
                                          fontWeight: '400',
                                          fontSize: '12px',
                                          lineHeight: '20px',
                                        }}
                                        variant="subtitle2"
                                        noWrap
                                      >
                                        {row?.measure}
                                      </Typography>
                                    </TableCell>
                                    {!isViewMode && (
                                      <TableCell component="th" align="left" scope="row">
                                        <Typography variant="subtitle2" noWrap>
                                          <Button sx={{ color: '#27A376' }}>
                                            <EditIcon
                                              onClick={() => {
                                                remove(index);
                                                const landmarks = values.nearestLandmarks[index];
                                                setFieldValue('landmarkNameAr', landmarks.landmarkNameAr);
                                                setFieldValue('landmarkNameEn', landmarks.landmarkNameEn);
                                                setFieldValue('distance', landmarks.distance);
                                                setFieldValue('measure', landmarks.measure);

                                                if (focusOnSelect.current) {
                                                  focusOnSelect.current.scrollIntoView({
                                                    behavior: 'smooth',
                                                    block: 'center',
                                                  });
                                                }
                                              }}
                                            />
                                          </Button>
                                          <Button sx={{ color: '#27A376' }}>
                                            <DeleteIcon onClick={() => remove(index)} />
                                          </Button>
                                        </Typography>
                                      </TableCell>
                                    )}
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                  );
                }}
              </FieldArray>
            </Grid>

            <Grid
              rowSpacing={2}
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
              style={{
                padding: '0.5rem 0.87rem 1rem',
                border: '1px solid #efefef',
                margin: '0.5rem',
                direction: isRtl ? 'rtl' : 'ltr',
                borderRadius: '14px',
                boxShadow: '3px 2px 5px #d6d5d5',
              }}
            >
              <Grid sx={{ mt: 3 }} item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('PLANS.planPictures')}
                </Typography>
                <UploadFileOnChange
                  viewMode={isViewMode}
                  uploadedFilesUploaded={uploadedFilesUploaded}
                  uniqueKey={uniqueKey + 2}
                  multiple
                  type="PICTURE"
                  category="PLAN"
                  label={t('PLANS.planPictures')}
                  fieldName="planPictures"
                  maxSize={5000000} // 5MB
                  key={new Date()}
                  acceptedTypes="image/*"
                  addItem={addItem}
                  deleteItem={deleteItem}
                />
                <Box>
                  {editData?.pictures &&
                    editData?.pictures?.map((file, index) => (
                      <PreviewFile
                        key={index}
                        ViewMode={isViewMode}
                        file={file}
                        onDelete={() =>
                          setDeleteFileData({
                            open: true,
                            id: file.id,
                            name: file.name,
                            type: 'sketch',
                          })
                        }
                      />
                    ))}
                </Box>
              </Grid>

              <Grid style={{ marginTop: '1.5em' }} item xs={2} sm={4} md={6}>
                <Stack>
                  <InputLabel id="planStatus">{t('PLANS.planStatus')}</InputLabel>
                  <Select
                    sx={{ mt: 1 }}
                    labelId="planStatus"
                    id="planStatus"
                    value={values.planStatus}
                    IconComponent={() => <CustomArrowIcon isRtl={isRtl} />}
                    disabled={isViewMode}
                    onChange={(event) => {
                      handleChange({
                        target: {
                          name: 'planStatus',
                          value: event.target.value,
                        },
                      });
                    }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (!selected) {
                        return <span style={{ color: '#9e9e9e' }}>{t('General.select')}</span>;
                      }
                      return selected === 'TRUE' ? (
                        <span>{t('General.Active')}</span>
                      ) : (
                        <span>{t('General.InActive')}</span>
                      );
                    }}
                  >
                    <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} value="TRUE">
                      {t('General.Active')}
                    </MenuItem>
                    <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} value="FALSE">
                      {' '}
                      {t('General.InActive')}
                    </MenuItem>
                  </Select>
                </Stack>
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Stack>
                  <InputLabel id="reservationStatus">{t('General.reservationStatus')}</InputLabel>
                  <Select
                    sx={{ mt: 1 }}
                    IconComponent={() => <CustomArrowIcon isRtl={isRtl} />}
                    labelId="reservationStatus"
                    id="reservationStatus"
                    disabled={isViewMode}
                    value={values.reservationStatus}
                    onChange={(event) => {
                      handleChange({
                        target: {
                          name: 'reservationStatus',
                          value: event.target.value,
                        },
                      });
                    }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (!selected) {
                        return <span style={{ color: '#9e9e9e' }}>{t('General.select')}</span>;
                      }
                      return selected === 'FREE' ? (
                        <span>{t('Units.free')}</span>
                      ) : (
                        <span> {t('Units.totallyReserved')} </span>
                      );
                    }}
                  >
                    <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} value="FREE">
                      {t('Units.free')}
                    </MenuItem>
                    <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} value="TOTALLY_RESERVED">
                      {t('Units.totallyReserved')}
                    </MenuItem>
                  </Select>
                </Stack>
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Stack>
                  <InputLabel id="reservationStatus">{t('PLANS.planDataStatus')}</InputLabel>
                  <Select
                    sx={{ mt: 1 }}
                    labelId="planDataStatus"
                    IconComponent={() => <CustomArrowIcon isRtl={isRtl} />}
                    disabled={isViewMode}
                    id="status"
                    value={values.planDataStatus}
                    onChange={(event) => {
                      handleChange({
                        target: {
                          name: 'planDataStatus',
                          value: event.target.value,
                        },
                      });
                    }}
                    displayEmpty
                    renderValue={(selected) => {
                      if (!selected) {
                        return <span style={{ color: '#9e9e9e' }}>{t('General.select')}</span>;
                      }
                      return selected === 'complete' ? (
                        <span>{t('Units.complete')}</span>
                      ) : (
                        <span>{t('Units.incomplete')}</span>
                      );
                    }}
                  >
                    <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} value="complete">
                      {t('Units.complete')}
                    </MenuItem>
                    <MenuItem sx={{ direction: isRtl ? 'rtl' : 'ltr' }} value="incomplete">
                      {t('Units.incomplete')}
                    </MenuItem>
                  </Select>
                </Stack>
              </Grid>
              <Grid
                style={{ marginTop: '0.9rem', paddingLeft: '1rem' }}
                rowSpacing={2}
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ fontWeight: 600, marginRight: '10px' }} color={'#111827'}>
                      {t('General.supportedDocuments')}
                    </Typography>
                    {loading ? (
                      <MenuItem>
                        <CircularProgress sx={{ color: '#111827' }} size={24} />
                      </MenuItem>
                    ) : (
                      documentTypeList?.map((option, index) => (
                        <Box
                          key={index}
                          sx={{
                            width: '91px',
                            height: '30px',
                            backgroundColor: 'rgba(231, 76, 60, 0.10)',
                            '&:hover': {
                              backgroundColor: 'primary.dark',
                              opacity: [0.9, 0.8, 0.7],
                              color: 'white',
                            },
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: ' 8px',
                            marginRight: '5px',
                            cursor: 'pointer',
                            fontSize: '10px',
                            fontWeight: '500',
                            lineHeight: '21px',
                          }}
                        >
                          {isRtl ? option.nameAr : option.nameEn}
                        </Box>
                      ))
                    )}
                  </Box>
                </Grid>
                <Grid item xs={2} sm={4} md={12}>
                  <UploadFileOnChange
                    viewMode={isViewMode}
                    uploadedFilesUploaded={uploadedFilesUploaded}
                    uniqueKey={uniqueKey + 3}
                    multiple
                    type="ATTACHMENT"
                    category="PLAN"
                    label={t('PLANS.planAttachments')}
                    fieldName="planAttachments"
                    maxSize={5000000}
                    acceptedTypes="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    addItem={addItem}
                    deleteItem={deleteItem}
                  />
                  <Box>
                    {editData?.attachments &&
                      editData?.attachments?.map((file, index) => (
                        <PreviewFile
                          key={index}
                          ViewMode={isViewMode}
                          file={file}
                          onDelete={() =>
                            setDeleteFileData({
                              open: true,
                              id: file.id,
                              name: file.name,
                              type: 'attachment',
                            })
                          }
                        />
                      ))}
                  </Box>
                </Grid>
                <Grid item xs={2} sm={4} md={6}>
                  <Stack direction={'row'} alignItems={'center'}>
                    {' '}
                    <Switch
                      checked={changeVirtualTour}
                      name="virtualType"
                      onChange={() => {
                        handleChange('virtualType');
                        setChangeVirtualTour(!changeVirtualTour);
                      }}
                    />
                    <Typography variant="body1">
                      {changeVirtualTour ? t('General.virtualTour') : t('General.virtualTourURL')}
                    </Typography>
                  </Stack>
                  {changeVirtualTour ? (
                    <>
                      <UploadFileOnChange
                        viewMode={isViewMode}
                        uploadedFilesUploaded={uploadedFilesUploaded}
                        uniqueKey={uniqueKey + 4}
                        type="VIRTUAL_TOUR"
                        category="PLAN"
                        label={t('General.virtualTour')}
                        fieldName="virtualTour"
                        maxSize={5000000} // 5MB
                        acceptedTypes="image/*"
                        addItem={addItem}
                        deleteItem={deleteItem}
                      />
                      {editData?.virtualTour && uploadedFilesUploaded?.virtualTour?.length === 0 && (
                        <PreviewFile file={editData.virtualTour} />
                      )}
                    </>
                  ) : (
                    <TextField
                      sx={{ marginBottom: 2 }}
                      name="virtualLink"
                      placeholder={t('General.virtualTourURL')}
                      fullWidth
                      value={values.virtualLink}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  )}
                </Grid>
              </Grid>
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="body1" gutterBottom>
                  {t('General.primaryPhoto')}
                </Typography>
                <UploadFileOnChange
                  setFieldValue={setFieldValue}
                  uploadedFilesUploaded={uploadedFilesUploaded}
                  uniqueKey={uniqueKey + 5}
                  viewMode={isViewMode}
                  type="PHOTO"
                  category="PLAN"
                  multiple={false}
                  label={t('General.primaryPhoto')}
                  fieldName="primaryPhoto"
                  maxSize={5000000} // 5MB
                  key={new Date().getDate()}
                  acceptedTypes="image/*"
                  addItem={addItem}
                  deleteItem={deleteItem}
                />
                <Box>
                  {editData?.primaryPhoto && uploadedFilesUploaded?.primaryPhoto?.length === 0 && (
                    <PreviewFile
                      file={{
                        path: editData?.primaryPhoto,
                        displayName: editData?.primaryPhoto,
                        id: uploadedFilesUploaded?.primaryPhoto?.length,
                      }}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>

            <Grid style={{ marginTop: '5px' }} item xs={2} sm={12} md={12}>
              <FormGroup>
                <Field name="states" t={t} viewMode={isViewMode} isRtl={isRtl} component={CustomSwitch} />
              </FormGroup>
            </Grid>
          </Grid>
          <DeleteFileModal
            open={deleteFileData.open}
            setOpen={deleteFileData}
            handelClose={() => setDeleteFileData({ ...deleteFileData, open: false })}
            onDelete={() => {
              handleDelete(deleteFileData?.id, deleteFileData?.type, deleteFileData?.name);
              setDeleteFileData({ ...deleteFileData, open: false });
              setRefetch(!refetch);
            }}
          />
          <Stack sx={{ width: '100%' }} justifyContent="end" alignItems={isRtl ? 'start' : 'end'} spacing={2}>
            <Button sx={{ mt: 4, border: '1px solid #111827' }} size="large" type="submit" variant="contained">
              {t('General.next')}
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default GeneralData;
