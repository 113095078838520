import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useLocation } from 'react-router-dom';
// @mui
import { Box, Drawer, IconButton, useTheme } from '@mui/material';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

// mock
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
import { buildNavConfig } from './config';

// ----------------------------------------------------------------------

const NAV_WIDTH_OPEN = 280;

const NAV_WIDTH_CLOSED = 60;

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav, setOpenNav }) {
  const { i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'lg');

  const isDarkMode = theme.palette.mode === 'dark';

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#f5fcff',
        },
      }}
    >
      {/* <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}> <Logo /> </Box> */}
      <NavSection open={openNav} data={buildNavConfig} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );
  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },

        width: { lg: openNav ? NAV_WIDTH_OPEN : NAV_WIDTH_CLOSED },
      }}
    >
      {isDesktop ? (
        <Drawer
          variant="permanent"
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              right: 0,
              paddingX: 0.5,
              transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
              width: { lg: openNav ? NAV_WIDTH_OPEN : NAV_WIDTH_CLOSED },
              height: { lg: '100%' },
              backgroundColor: '#f5fcff',

              border: 'none',
            },
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: 60,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <IconButton
              sx={{
                cursor: 'pointer',
                height: '30px',
                width: '30px',
                backgroundColor: isDarkMode ? '#000000' : '#0caf60',

                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                color: 'white',
                fontSize: '22px',
                transition: 'all 0.2s ease',
                '&:hover': {
                  backgroundColor: 'rgba(99, 115, 129, 0.08)',
                },
                ...(isRtl
                  ? {
                      transform: 'rotate(180deg)',
                    }
                  : {
                      transform: 'rotate(0deg)',
                    }),
                ...(openNav
                  ? {
                      ...(isRtl
                        ? {
                            transform: 'rotate(0)',
                          }
                        : {
                            transform: 'rotate(180deg)',
                          }),
                    }
                  : {}),
              }}
              onClick={() => setOpenNav(!openNav)}
            >
              <KeyboardDoubleArrowRightIcon />{' '}
            </IconButton>
          </Box>{' '}
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            width: { lg: openNav ? NAV_WIDTH_OPEN : NAV_WIDTH_CLOSED },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
